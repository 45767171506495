$(document).ready(function(){
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$ajaxUrl = $('.ajaxlocation').data('ajaxlocation'),
		$mainWrapper = $('.wrapper-holder');

		// CUSTOM FUNCTIONS
		tableClass();
		mobileLayout();
		projectModal($ajaxUrl);
		//wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		related_item_slider();
		active_state();
		serviceSlider();
    custom_file_upload();
    careers_modal();
    
		$('.home-right-scroll').mCustomScrollbar({
			scrollInertia: "500"
		});
		$('.loader-overlay').fadeOut(200);
	});
	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
	});
});
function projectModal($ajaxUrl){
	$(document).on('click', 'div.project-category-link',function(e){
		var projectId = $(this).data('myid');
		console.log(projectId);
		var $loader = $('.project-ajax-loader');
		$.ajax({
               url : $ajaxUrl,
               type : 'POST',
               dataType: 'json',
               data : {
                   action : 'project_select',
                   post_id : projectId,
               },
                beforeSend: function(){
                	$loader.fadeIn();
                },
                success: function(response) {
                    var result  = response.output;
                    $('.project-ajax-modal').html(result);
                    $loader.fadeOut();
               }
          });
	});
}
function animateSidebar(){
	$(".icon-holder").mouseenter(function() {
   		$(this).addClass('animate-sidebar');
   		$('.icon-holder h3').addClass('showtext');
   		setTimeout(function(){
   		$('.icon-holder-text').each(function(i){
	      setTimeout(function(){
	        $('.icon-holder-text').eq(i).addClass('is-showing');
	      }, (700 * (Math.exp(i * 0.14))) - 700);
	    });
   	},500);
	}).mouseleave(function(){
	    $('.icon-holder h3').removeClass('showtext');
	    $('.icon-holder-text').removeClass('is-showing');
	    setTimeout(function(){
	    	$('.icon-holder').removeClass('animate-sidebar');
	    }, 500);
	});
}
function mySlider(){
	$(".home-slider").slick({
	  autoplay: true,
	  infinite: true,
	  autoplaySpeed: 10000,
	  speed: 800,
	  slidesToShow: 1,
	  fade: true,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: true,
	  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-caret-left"></button>',
	  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-caret-right"></button>',
	});
	$('.home-slider').on('init', function(e, slick) {
		$('.home-slider .slick-list .slick-track .home-slider-content .home-slider-background').addClass('zoom-effect');
	    var $animatingElements = $('div.slick-slide[data-slick-index="0"]').find('[data-animation]');
	    doSlickAnimations($animatingElements);
	 });
	 $('.home-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
		var $animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
		$('div.slick-slide[data-slick-index="'+ nextSlide+'"]').find('.home-slider-background').removeClass('zoom-effect');
		doSlickAnimations($animatingElements);
	});
	 $('.home-slider').on('afterChange', function(e, slick, currentSlide, nextSlide) {
      	$('div.slick-slide[data-slick-index="'+currentSlide+'"]').find('.home-slider-background').addClass('zoom-effect');
    });
}
function serviceSlider(){
	$('.service-slider').slick({
	  autoplay: true,
	  dots: true,
	  infinite: false,
	  speed: 300,
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: false,
	  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1,
	        infinite: true,
	        dots: false
	      }
	    },
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 568,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	    // You can unslick at a given breakpoint now by adding:
	    // settings: "unslick"
	    // instead of a settings object
	  ]
	});
}
function doSlickAnimations(elements) {
  var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  elements.each(function(){
    var $this = $(this);
    var $animationDelay = $this.data('delay');
    var $animationType = 'animated ' + $this.data('animation');
    $this.css({
        'animation-delay': $animationDelay,
        '-webkit-animation-delay': $animationDelay
    });
    $this.addClass($animationType).one(animationEndEvents, function() {
        $this.removeClass($animationType);
    });
  });
 }
/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	mySlider();
	animateSidebar();
}
else if( Modernizr.mq('(min-width: 0)') && Modernizr.mq('(max-width: 1199px)') ) {

}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
}
else{
}
